import React from 'react';
import Moment from 'moment';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import {Persist} from '../../utils/formik-persist';
import {makeStyles, Paper, Button, TextField, CircularProgress, Checkbox, Link, Grid} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import breakpoints from 'wobi-web-common/dist/config/breakpoints';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {Blue} from 'wobi-web-common/dist/config/colors.config';
import {AutocompleteSingleSelect} from 'wobi-web-common/dist/components/Autocomplete';
import cities from 'wobi-web-common/dist/config/cities';
import EzFormControl from 'wobi-web-common/dist/components/EzFormControl';
import {destinationsToNames} from '../../utils/routingHelpers';
import {validateForHebrew, validateForEnglish, validatePhoneNumber, validateEmail} from '../../utils/validationHelpers';
import {getCityStreets, handleAdditionalDetailsSubmit} from '../../utils/apiHandlers';
import {getOffersFields, preSaveHandler} from '../../utils/persistOfferDetails';
import {fireGtmEvent} from '../../utils/marketing.utils';
import logos from '../../assets/images/company-logos';
import ErrorMessagePopup from '../../components/modals/ErrorMessage';
import Tab3YesNo from '../../components/Tab3YesNo';
import {getAge} from '../../utils/dateHelpers';
import {ridersTypes} from '../../config/ridersTypes';
import { agentDeveloperUtils } from 'wobi-web-common';

const firstNameValidationText = 'נא להזין שם פרטי באנגלית';
const lastNameValidationText = 'נא להזין שם משפחה באנגלית';
const streetValidationText = 'נא להזין שם רחוב בעברית';

const CompleteDetails = React.memo(({className, offer, initialTabs, submitAct}) => {
  const intl = useIntl();
  const messages = (id) => intl.formatMessage({id});
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);
  const classes = useStyles();
  const [cityCode, setCityCode] = React.useState(null);
  const [streets, setStreets] = React.useState([]);
  const [initStreet, setInitStreet] = React.useState(true);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isErrorModalOpen, setErrorModalOpen] = React.useState(false);
  const [validationObject, setValidationObject] = React.useState({
    city_c: yup.string().typeError().required('שדה חובה'),
    street: validateForHebrew(streetValidationText),
    buildingNumber: yup.number().typeError('יש להזין מספר').required('שדה חובה'),
    firstNameEng: validateForEnglish(firstNameValidationText),
    lastNameEng: validateForEnglish(lastNameValidationText),
    email: validateEmail(),
  });
  const [ridersList, setRidersList] = React.useState([]);

  React.useEffect(() => {
    const riders = [];
    const formikData = JSON.parse(sessionStorage.getItem('travel.details-form')).values;
    for (const {name, formikKey} of ridersTypes) {
      if (formikData[formikKey]) {
        riders.push(name);
      }
    }
    setRidersList(riders);
  }, []);

  const isProviderClal = () => offer.provider === 'CLAL';
  const isAgeOver18 = dob => getAge(dob) >= 18;
  const isHidePermementlyClalPaymentFields = true;
  const showClalPaymentFields = dob => !isHidePermementlyClalPaymentFields && isAgeOver18(dob) && isProviderClal();
  const isAdvanceToCreditCardEnabled = true;
  const showClalAandvceToCreditCardField = isAdvanceToCreditCardEnabled && isProviderClal();

  React.useEffect(() => {
    if (cityCode > 0) {
      getCityStreets(cityCode)
        .then(string => {
          setStreets(string.data);
        })
        .catch(console.log);
    }
  }, [cityCode]);

  React.useEffect(() => {
    const newValidation = {...validationObject};
    if (initialTabs.policyholdNum > 1) {
      const countArray = Array.from(new Array(initialTabs.policyholdNum + 1).keys()).slice(2);
      Object.keys(countArray).forEach(indx => {
        newValidation['firstNameEng' + countArray[indx]] = validateForEnglish(firstNameValidationText);
        newValidation['lastNameEng' + countArray[indx]] = validateForEnglish(lastNameValidationText);
      });
      setValidationObject(newValidation);
    }

    if (isProviderClal()) {
      const savePaymentInfoErrorMessage = 'יש לאשר את תנאי השימוש';

      if (isAgeOver18(initialTabs.dob1) && !showClalAandvceToCreditCardField) {
        newValidation.payWithDigitalCard = yup.string().required('שדה חובה');
        newValidation.savePaymentInfo = yup.boolean().when('payWithDigitalCard', {
          is: 'yes',
          then: yup.boolean().oneOf([true], savePaymentInfoErrorMessage).required(savePaymentInfoErrorMessage),
        });
      }

      if (initialTabs.travelers.length > 1) {
        Array.from(new Array(initialTabs.policyholdNum + 1).keys()).slice(2)
          .forEach((index) => {
            if (isAgeOver18(initialTabs[`dob${index}`]) && !showClalAandvceToCreditCardField) {
              newValidation[`payWithDigitalCard${index}`] = yup.string().required('שדה חובה');
              newValidation[`additionalInsuredEmail${index}`] = yup.string().when(`payWithDigitalCard${index}`, {
                is: 'yes',
                then: validateEmail(),
              });
              newValidation[`additionalInsuredPhone${index}`] = yup.string().when(`payWithDigitalCard${index}`, {
                is: 'yes',
                then: validatePhoneNumber(),
              });
              newValidation[`savePaymentInfo${index}`] = yup.boolean().when(`payWithDigitalCard${index}`, {
                is: 'yes',
                then: yup.boolean().oneOf([true], savePaymentInfoErrorMessage).required(savePaymentInfoErrorMessage),
              });
            }
          });
      }

      setValidationObject(newValidation);
    }
  }, [initialTabs]);

  const firstDetailsSumHeader = ({targetCountries, policyholdNum, policyStartDate, policyEndDate}) => `
    ${offer.productLineName}
    <span>${offer.productLineName ? ' | ' : ''}</span>
    <span>${messages('step3.destination')}</span>
    ${destinationsToNames(targetCountries)}
    <span>${policyStartDate && policyEndDate ? ' | ' : ''}</span>
    <span>${messages('step3.stayOf')}</span>
    ${Number(Moment(policyEndDate).diff(Moment(policyStartDate), 'days')) + 1}
    ${messages('step3.stayOf2')}
    <span>${destinationsToNames(targetCountries) ? ' | ' : ''}</span>
    <span>${intl.formatHTMLMessage({id: 'step3.insuredNo.'})}</span> ${policyholdNum}
    `;

  const handleCityChange = (value) => cities
    .filter(item => item.name.slice(0, value.length) === value)
    .map(item => Object.assign(item, {
      label: item.name,
      value: item.code,
    }));

  const handleStreetChange = (value) => {
    if (streets && streets.length > 0) {
      return streets
        .filter(item => item.name.startsWith(value))
        .map(item => ({
          label: item.name,
          value: item.name,
        }));
    } else if (initStreet) {
      const objectDetails = JSON.parse(sessionStorage.getItem('travel.complete-details'));
      if (!objectDetails) {
        return '';
      }
      const {street} = objectDetails.values;
      setInitStreet(false);
      return [{
        label: street,
        value: street,
      }];
    } else {
      return '';
    }

    // if (!hebrewCharsRegExp.test(value)) {
    //   return [{label: 'הטקסט צריך להיות בעברית'}];
    // }
  };

  const getCityValue = () => {
    const objectDetails = JSON.parse(sessionStorage.getItem('travel.complete-details'));
    if (!objectDetails) {
      return null;
    }
    const {city_c, city_name} = objectDetails.values;
    setCityCode(city_c);
    return city_name;
  };

  const handleCitySelect = (event, setFieldValue) => {
    setFieldValue('city_name', event ? event.name : '');
    setFieldValue('city_c', event ? event.value : '');
    if (event && event.value !== cityCode) {
      setCityCode(event.value);
    }
  };

  const handleSubmit = async (values, validateForm, setFieldTouched) => {
    setSubmitting(true);
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      fireGtmEvent('confirmTravelAdditionalDetails');
      const resp = await handleAdditionalDetailsSubmit({...values,
        offersCallId: getOffersFields('offersCallId')});
      setSubmitting(false);
      if (resp && resp.success) {
        submitAct();
      } else {
        setErrorModalOpen(true);
      }
    } else {
      Object.keys(validationObject).forEach(field => setFieldTouched(field, true));
      setSubmitting(false);
    }
  };

  /* prepare a block for every additional traveler (besides the mainTraveler), with summary-header & English names */
  const AdditionalTravelersFormBlocks = (values, setFieldValue) => {
    const countArray = Array.from(new Array(initialTabs.policyholdNum + 1).keys()).slice(2);
    const formBlocks = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(countArray).map(indx => {
      const payWithDigitalCard = values[`payWithDigitalCard${countArray[indx]}`];
      if (payWithDigitalCard === undefined || payWithDigitalCard === null || payWithDigitalCard === '') {
        setFieldValue(`payWithDigitalCard${countArray[indx]}`, 'no');
      }
      formBlocks.push(
        <div className={classes.travelerContainer} isMobile={isMobile} key={`blockNo-${indx}`}>
          <h4>
            <b>
              {messages(`step3.traveler${initialTabs['gender' + countArray[indx]] === '2' ?
                '-f' : ''}`)}
              {' '}
              {countArray[indx]}
            </b>
            <span>
              {`${initialTabs['firstName' + countArray[indx]]} ${initialTabs['lastName' + countArray[indx]]}  |
            ${initialTabs['ID' + countArray[indx]]} |
            ${Moment(initialTabs['dob' + countArray[indx]]).format('DD-MM-YYYY')}`}
            </span>
          </h4>
          <div>
            <div className='w-third'>
              <EzFormControl name={`firstNameEng${countArray[indx]}`}
                label={messages('step3.firstname_eng')} isLabelInChild>
                <TextField className='travelerFields'
                  onChange={(event) => setFieldValue(`firstNameEng${countArray[indx]}`, event.target.value)}
                  value={values['firstNameEng' + countArray[indx]]}
                  data-testid={`first-name-${countArray[indx]}`}
                />
              </EzFormControl>
            </div>
            <div className='w-third'>
              <EzFormControl name={`lastNameEng${countArray[indx]}`}
                label={messages('step3.lastname_eng')} isLabelInChild>
                <TextField className='travelerFields'
                  onChange={(event) => setFieldValue(`lastNameEng${countArray[indx]}`, event.target.value)}
                  value={values['lastNameEng' + countArray[indx]]}
                  data-testid={`last-name-${countArray[indx]}`}
                />
              </EzFormControl>
            </div>
          </div>
          {showClalPaymentFields(initialTabs[`dob${countArray[indx]}`]) ? (
            <>
              <Tab3YesNo
                className='sa'
                name={`payWithDigitalCard${countArray[indx]}`}
                dataTestId='pay-with-digital-card'
                label={<FormattedHTMLMessage id='step3.payWithDigitalCard' />}
                value={values[`payWithDigitalCard${countArray[indx]}`]}
                values={values}
                onChange={event => setFieldValue(`payWithDigitalCard${countArray[indx]}`, event.target.value)}
              />
              {values[`payWithDigitalCard${countArray[indx]}`] === 'yes' ? (
                <>
                  <div>
                    <div className='w-third'>
                      <EzFormControl
                        name={`additionalInsuredPhone${countArray[indx]}`}
                        label={messages('step3.additionalInsuredPhone')} isLabelInChild
                      >
                        <TextField
                          className='travelerFields'
                          onChange={
                            event => setFieldValue(`additionalInsuredPhone${countArray[indx]}`, event.target.value)
                          }
                          value={values[`additionalInsuredPhone${countArray[indx]}`]}
                          data-testid='additional-insured-phone'
                        />
                      </EzFormControl>
                    </div>
                    <div className='w-third'>
                      <EzFormControl
                        name={`additionalInsuredEmail${countArray[indx]}`}
                        label={messages('step3.additionalInsuredEmail')} isLabelInChild
                      >
                        <TextField
                          className='travelerFields'
                          onChange={
                            event => setFieldValue(`additionalInsuredEmail${countArray[indx]}`, event.target.value)
                          }
                          value={values[`additionalInsuredEmail${countArray[indx]}`]}
                          data-testid='additional-insured-email'
                        />
                      </EzFormControl>
                    </div>
                  </div>
                  <EzFormControl name={`savePaymentInfo${countArray[indx]}`} isInlineLabel showError>
                    <Checkbox
                      aria-label={intl.formatMessage({id: 'step3.savePaymentInfoAgree'})}
                      id={`savePaymentInfo${countArray[indx]}`}
                      data-testid='save-payment-info'
                      onChange={event => setFieldValue(`savePaymentInfo${countArray[indx]}`, event.target.checked)}
                      checked={values[`savePaymentInfo${countArray[indx]}`]}
                    />
                    <span className={classes.terms}>
                      {intl.formatMessage({id: 'step3.savePaymentInfoAgree'})}
                    </span>
                  </EzFormControl>
                </>
              ) : null}
            </>
          ) : null}
        </div>,
        <div>
          {showClalAandvceToCreditCardField ? (
            <>
              <h5 className={classes.advanceToCreditCardField}>
                <FormattedHTMLMessage id='step3.advanceToCreditCard'/>
              </h5>
            </>
          ) : null}
        </div>,
      );
    });
    return formBlocks;
  };

  return (
    <div className={className} data-testid='complete-details-container'>
      <Formik
        validationSchema={yup.object().shape(validationObject)}
        initialValues={{
          city_c: '',
          street: '',
          buildingNumber: '',
          firstNameEng: '',
          lastNameEng: '',
          payWithDigitalCard: '',
          savePaymentInfo: '',
          email: '',
        }}>
        {({values, setFieldValue, validateForm, setFieldTouched}) => (
          <Form>
            <div className={classes.firstDetailsSumRow}>
              <div className={classes.firstDetailsSumHeaders}>
                <span dangerouslySetInnerHTML={{__html: firstDetailsSumHeader(initialTabs)}}/>
                {ridersList.map((rider, index) => (<span key={`spanRider${index}`}>
                  {index === 0 ? (
                    <span >
                        |
                    </span>
                  ) :
                    <text className={classes.riderSubheaderItem} >
                        |
                    </text>
                  }
                  <text key={index} className={classes.riderSubheaderItem}>
                    {index === 0 ? 'כולל: ' : ''}
                    {index === 0 ? <span style={{whiteSpace: 'pre-wrap'}}>{' '}</span> : ''}
                    {rider}
                  </text>
                </span>
                ))}
              </div>

              <img src={logos[offer.logo]} alt={offer.providerName} />
            </div>
            <Paper className={classes.paperTravelers} elevation={0}>
              <Paper className={classes.paperInner} elevation={0}>
                <div className={classes.travelerContainer} isMobile={isMobile}>
                  <Grid style={{
                    justifyContent: 'space-between',
                    maxWidth: 'none',
                  }} container spacing={0}>
                    <div className={classes.priceContainer} >
                      <h4>
                        <b>{messages(`step3.mTraveler${initialTabs.gender1 === '2' ? '-f' : ''}`)}</b>
                        <span>
                          {`${initialTabs.firstName1} ${initialTabs.lastName1} | ${initialTabs.ID1} |
                        ${Moment(initialTabs.dob1).format('DD-MM-YYYY')}`}
                        </span>
                      </h4>
                      <box className={classes.middled}>
                        <span dir='rtl' className={classes.priceText}>
                          {messages('payment.total_payment_original')}
                          {' '}
                        </span>
                        <span dir='rtl' className={classes.currencySymbol}>
                            $
                          <span className={classes.offerPrice}>{Number.parseFloat(offer.price).toFixed(2)}</span>
                        </span>
                      </box>
                    </div>
                  </Grid>
                  <div>
                    <div className='w-third'>
                      <EzFormControl name='firstNameEng'
                        label={messages('step3.firstname_eng')} isLabelInChild>
                        <TextField className='travelerFields'
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                          onChange={(ev) => setFieldValue('firstNameEng', ev.target.value)}
                          value={values.firstNameEng}
                          data-testid='first-name-1'
                        />
                      </EzFormControl>
                    </div>
                    <div className='w-third'>
                      <EzFormControl name='lastNameEng'
                        label={messages('step3.lastname_eng')} isLabelInChild>
                        <TextField className='travelerFields'
                          onChange={(ev) => setFieldValue('lastNameEng', ev.target.value)}
                          value={values.lastNameEng}
                          data-testid='last-name-1'
                        />
                      </EzFormControl>
                    </div>
                    <div className='w-third'>
                      <EzFormControl name='email'
                        label={messages('step3.email')} isLabelInChild>
                        <TextField className='travelerFields'
                          onChange={(ev) => setFieldValue('email', ev.target.value)}
                          value={values.email}
                          data-testid='complete-details-email'
                        />
                      </EzFormControl>
                    </div>
                    <div className='w-large select' data-testid='complete-details-city'>
                      <EzFormControl name='city_c' isInlineLabel={false}>
                        <AutocompleteSingleSelect
                          id='city_c'
                          className='travelerFields'
                          onInput={handleCityChange}
                          onSelect={(ev) => handleCitySelect(ev, setFieldValue)}
                          placeholder={messages('step3.select_city')}
                          parentValue={getCityValue()}
                        />
                      </EzFormControl>
                    </div>
                    <div className='w-large select' data-testid='complete-details-street'>
                      <EzFormControl name='street' isInlineLabel={false}>
                        <AutocompleteSingleSelect
                          id='street'
                          className='travelerFields'
                          isCreatable
                          onInput={handleStreetChange}
                          onSelect={(ev) => setFieldValue('street', ev ? ev.value : '')}
                          placeholder={messages('step3.select_street')}/>
                      </EzFormControl>
                    </div>
                    <div className='w-small'>
                      <EzFormControl name='buildingNumber'
                        label={messages('step3.building_number')} isLabelInChild>
                        <TextField className='travelerFields'
                          onChange={(ev) => setFieldValue('buildingNumber', ev.target.value)}
                          value={values.buildingNumber}
                          data-testid='complete-details-building-number'
                        />
                      </EzFormControl>
                    </div>
                  </div>
                  {showClalPaymentFields(initialTabs.dob1) ? (
                    <>
                      <Tab3YesNo
                        name='payWithDigitalCard'
                        dataTestId='pay-with-digital-card'
                        label={<FormattedHTMLMessage id='step3.payWithDigitalCard' />}
                        value={values.payWithDigitalCard}
                        values={values}
                        onChange={event => setFieldValue('payWithDigitalCard', event.target.value)}
                      />
                      {values.payWithDigitalCard === 'yes' ? (
                        <EzFormControl name='savePaymentInfo' isInlineLabel showError>
                          <Checkbox
                            aria-label={intl.formatMessage({id: 'step3.savePaymentInfoAgree'})}
                            id='savePaymentInfo'
                            data-testid='save-payment-info'
                            onChange={event => setFieldValue('savePaymentInfo', event.target.checked)}
                            checked={values.savePaymentInfo}
                          />
                          <span className={classes.terms}>
                            {intl.formatMessage({id: 'step3.savePaymentInfoAgree'})}
                          </span>
                        </EzFormControl>
                      ) : null}
                    </>
                  ) : null}
                </div>
                <div>
                  {showClalAandvceToCreditCardField ? (
                    <>
                      <h5 className={classes.advanceToCreditCardField}>
                        <FormattedHTMLMessage id='step3.advanceToCreditCard' />
                      </h5>
                    </>
                  ) : null}
                </div>

                {AdditionalTravelersFormBlocks(values, setFieldValue)}

                <Button
                  className={classes.submitBtn}
                  onClick={() => handleSubmit(values, validateForm, setFieldTouched)}
                  data-testid='complete-details-continue-purchase'
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : messages('continueToPayment')}
                </Button>

                <ErrorMessagePopup onClose={() => setErrorModalOpen(false)} isOpen={isErrorModalOpen}/>

              </Paper>
            </Paper>
            <Persist name='travel.complete-details' isSessionStorage='true' preSaveHandler={preSaveHandler}/>
          </Form>
        )}
      </Formik>
    </div>
  );
});

const useStyles = makeStyles(theme => ({
  firstDetailsSumRow: {
    padding: '0 15px',
    marginBottom: 7,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',

    [theme.breakpoints.down(500)]: {
      flexDirection: 'column',
      justifyContent: 'center',

      '&>img': {
        order: 1,
        marginRight: 'auto',
      },
      '&>div': {
        order: 2,
        marginRight: 'auto',
      },
    },
  },
  advanceToCreditCardField: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textAlign: 'left',
    marginRight: '12px',
  },
  middled: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    marginRight: '12px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
  },
  priceText: {
    fontSize: theme.typography.pxToRem(21),
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'baseline',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'column-reverse',
    },
  },
  currencySymbol: {
    color: theme.palette.primary.main,
  },
  offerPrice: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(38.5),
  },
  riderSubheaderItem: {
    padding: '0 4px',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    display: 'inline-block',
    color: agentDeveloperUtils.getPrimaryColor(440),
  },
  firstDetailsSumHeaders: {
    marginLeft: 10,
    '&>span': {
      fontSize: theme.typography.fontSize,
      fontWeight: 'bold',
      color: theme.palette.secondary.main,
      '&>span': {
        color: theme.palette.text.primary,
        fontWeight: 400,
      },
    },
  },
  paperTravelers: {
    borderRadius: 12,
    backgroundColor: Blue[20],
    padding: 15,
    margin: 0,
  },
  paperInner: {
    borderRadius: 10,
    backgroundColor: theme.palette.background.default,
    border: '1px solid ' + Blue[100],
    width: '100%',
    position: 'relative',
    padding: 10,
    '&:not(:last-child)': {marginBottom: 15},
    '& div:nth-of-type(1)': {marginTop: 0},
  },
  submitBtn: {
    fontSize: theme.typography.pxToRem(15.75),
    lineHeight: '18px',
    height: 44,
    width: 170,
    margin: '16px 0 20px 12px',
  },
  travelerContainer: {
    '& .nomargin': {
      margin: '5px 0',
    },
    marginTop: '50px',
    '& h4': {
      margin: '12px 12px 18px 0',
      lineHeight: '18px',
      '& > b': {
        fontSize: theme.typography.pxToRem(15.75),
        fontWeight: 700,
        color: theme.palette.primary.main,
        marginRight: 12,
      },
      '& > span': {
        fontSize: theme.typography.pxToRem(12.25),
        fontWeight: 400,
        color: theme.palette.secondary.main,
      },
    },
    '& > div': {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxWidth: 760,
    },
    '& .MuiInputBase-input': {
      padding: '10px 14px 11px 20px',
      fontSize: theme.typography.fontSize,
      fontWeight: 600,
    },
    '& .select div[class^=makeStyles-root]': {height: 40},
    '& .select p': {
      bottom: 9,
      fontSize: theme.typography.pxToRem(12.25),
    },
    '& .travelerFields': {width: '100%'},
    '& .travelerFields .MuiInputBase-input': {
      padding: '10px 20px 11px 14px',
      fontSize: theme.typography.fontSize,
      fontWeight: 600,
    },
    '& .w-large.select .MuiInputBase-input': {padding: 0},
    '& .travelerFields label': {
      transform: 'translate(20px,10px)',
      fontSize: theme.typography.pxToRem(12.25),
    },
    '& .travelerFields legend': {fontSize: theme.typography.pxToRem(10.5)},
    '& .w-third, .w-small, .w-large': {margin: '0 12px 10px'},
    '& .w-small': {width: 94},
    '& .w-third': {
      [theme.breakpoints.down(510)]: {width: '100%'},
    },
    '& .w-large': {
      width: 253,
      [theme.breakpoints.down(633)]: {width: 'calc(100% - 142px)'},
      [theme.breakpoints.down(510)]: {width: '100%'},
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: theme.typography.pxToRem(11.375),
      margin: '4px 20px 3px 0',
    },
  },
}));

CompleteDetails.propTypes = {
  className: PropTypes.string,
  offer: PropTypes.object.isRequired,
  initialTabs: PropTypes.shape({
    gender1: PropTypes.string,
    firstName1: PropTypes.string,
    lastName1: PropTypes.string,
    ID1: PropTypes.string,
    dob1: PropTypes.string,
    policyholdNum: PropTypes.number,
    travelers: PropTypes.array,
  }),
  submitAct: PropTypes.func,
};

export default CompleteDetails;
