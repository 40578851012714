/* eslint-disable no-useless-concat */
/* eslint-disable max-len */
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';

const offersNumber = agentDeveloperUtils.getOffersUpTo() ? agentDeveloperUtils.getOffersUpTo() : 6;

export default {
  yes: 'כן',
  no: 'לא',
  cancel: 'ביטול',
  add_cover: 'הוספת כיסוי',
  male: 'זכר',
  female: 'נקבה',
  continue: 'המשך',
  continueToOffers: 'המשך להצעות',
  continueToPayment: 'המשך לתשלום',

  an_error_occurred_1: 'אופס... חלה תקלה באתר :(',
  an_error_occurred_2: 'אנא נסו שוב מאוחר יותר',

  asia: 'אסיה',
  usa: 'ארה"ב',
  canada: 'קנדה',
  southAmerica: 'אמריקה הלטינית',
  southam: 'אמריקה הלטינית',
  europe: 'אירופה',
  africa: 'אפריקה',
  australia: 'אוסטרליה',

  vacationInsurance: 'ביטוח חופשה',
  travelInsurance: 'ביטוח נסיעות',

  'validation.required': 'שדה חובה',
  'validation.payment_submit_error': 'אחד או יותר מפרטי האשראי שהזנת אינם תקינים',
  'validation.payment_debit_card_not_allowed': 'לא ניתן לשלם בכרטיס מסוג דביט',
  'validation.needToFillAllFields': 'יש למלא תחילה את כל פרטי הטופס',
  'validation.fieldLengthExceedsLimit': 'אורך השדה גדול מהמותר',
  'validation.invalidYear': 'שנה לא תקינה',

  'step1.tab1.gettingStarted': 'מתחילים!',
  'step1.tab1.fillNextFields': 'מלאו את הפרטים הבאים- וקבלו עד ' + offersNumber + ' הצעות לביטוח',
  'step1.tab1.start_date': 'התחלת הנסיעה',
  'step1.tab1.end_date': 'סיום הנסיעה',
  'step1.tab1.travelDates': 'מהם תאריכי הנסיעה?',
  'step1.tab1.days_num_message': 'ימים מבוטחים: ',
  'step1.tab1.targetTitle': 'מהו יעד הנסיעה?',
  'step1.tab1.target': 'יעד הנסיעה',
  'step1.tab1.targetTitleRemark': 'ניתן לבחור יותר מאחד',

  'step1.tab2.isLeftIsrael': 'כל המבוטחים יצאו מישראל בתאריך תחילת הפוליסה',
  'step1.tab2.selectNumTitle': 'מספר מבוטחים',
  'step1.tab2.DoBTitle_primary': 'מבוטח ראשי',
  'step1.tab2.DoBTitle': 'מבוטח',
  'step1.tab2.dob': 'תאריך לידה',
  'step1.tab2.firstName': 'שם פרטי',
  'step1.tab2.lastName': 'שם משפחה',
  'step1.tab2.ID': 'ת.ז',
  'step1.tab2.gender': 'מין',
  'step1.tab2.phone': 'טלפון נייד',

  'step1.tab3.isUSAMedicalCondition': 'האם בשנתיים האחרונות אובחנת או עברת אירוע מוחי מכל סוג, מחלת לב (לדוגמה: התקף לב, צנתור, ניתוח מעקפים, הפרעות קצב או בעיית לב אחרת), היצרות בעורקי הצוואר (קרוטיס)?',
  'step1.tab3.isTreatmentAbroad': 'האם הטיול הוא לצורך בירור או טיפול רפואי בחו"ל?',
  'step1.tab3.isMedicalEscalation': '<span>האם במהלך חצי השנה האחרונה חל שינוי במצב הבריאותי של אחד המבוטחים בפוליסה (לרבות פניה למיון, אשפוז (כולל אשפוז יום), ניתוח, תוצאות בדיקות לא תקינות, צורך בהשגחה?<span>',
  'step1.tab3.isMedicalEscalation1': '<span class=\'notation\'>אין צורך לסמן "כן" במידה ומדובר בטיפול הורמונלי, ויטמינים או תוספי מזון, אלרגיה, גלולות למניעת הריון, כולסטרול, תת פעילות בלוטת המגן, קשב וריכוז, לחץ דם, בעיות שינה, צרבות, ערמונית מוגדלת, מיגרנות, טיפול באספירין שלא בעקבות מחלה/ בעיה בלב/ אירוע מוחי או בעיה בקרישה.</span>',
  'step1.tab3.isMedicalTreatment': 'האם הופנית במהלך חצי השנה האחרונה לבדיקות שטרם בוצעו, או שתוצאותיהן לא היו תקינות או כללו ממצא רפואי חריג?',
  'step1.tab3.isMedicalTreatmentSmallText': '<span class=\'notation\'>פרוט הבדיקות: MRI (ראש או עמוד שדרה), CT (ראש או עמוד שדרה), אקו לב, דופלר עורקי צוואר, בדיקת מאמץ, הולטר לב, אולטרסאונד, רנטגן דופלר, מיפוי לב, בדיקות פולשניות (למעט בדיקות דם).</span>',
  'step1.tab3.isPregnancy': 'האם אחת מהמבוטחות בהריון?',
  'step1.tab3.isPregEndangered': 'האם אובחן לאחת המבוטחות הריון בסיכון גבוהה ו/או מרובה עוברים?',
  'step1.tab3.pregnancyIdentification': 'אנא סמן/י מי מהמבוטחות בהריון:',
  'step1.tab3.pregnancyWeek': 'באיזה שבוע להריון נמצאות המבוטחות בפוליסה עכשיו?',
  'step1.tab3.pregnancyWeek_notation': '(יש לציין מספר מלא של שבועות ההריון שכבר עברו בלבד)',
  'step1.tab3.isCovid19': 'האם קיים חשד או אינדיקציה שאחד הנוסעים הינו חולה בקורונה? לרבות הופעת סימפטומים, הפנייה לבדיקות אבחון.',
  'step1.tab3.isChronic': '<span>האם אחד מהמבוטחים בפוליסה' +
    ' אובחן כחולה במחלה כרונית או נוטל תרופות באופן קבוע?</span> ',
  'step1.tab3.isChronic2': `<span class='notation'> <img src={imgUrl} alt='' width=15 height=15>
   לתשומת ליבך, אין צורך לסמן "כן" במידה ומדובר בתוספי מזון, ויטמינים או גלולות למניעת הריון</span>`,
  'step1.tab3.chronic_link': '<a href="https://www.health.gov.il/hozer/sbn07_2015.pdf" target="_blank">' +
    'לפירוט אודות הגדרת מחלה כרונית</a>',
  'step1.tab3.chronic_tooltip': 'ויטמינים ותוספי מזון אינם נחשבים לתרופות',
  'step1.tab3.chronicIdentification': 'כדי לחסוך עלויות בפוליסה, סמן מי מהמבוטחים אובחן' +
    ' במחלה כרונית או נוטל תרופות באופן קבוע:',
  'step1.tab3.chronicIdentification_tooltip': '<span style="direction:rtl;display:inline-block;">תעריף הפוליסות ' +
    'המוצעות מושפע ממספר המבוטחים בעלי "מצב רפואי קיים"</span>',
  'step1.tab3.medicalCondition': 'האם אחד מהמובטחים בפוליסה אובחן כחולה במחלות הבאות:',
  'step1.tab3.medicalCondition_tooltip': 'חברות הביטוח מתייחסות אל המצבים הנ"ל כאל מצבים רגילים,' +
    ' כשכל אחד בפני עצמו לא גורר העלאת תעריף',
  'step1.tab3.isAdditionalChronic': 'האם בחצי השנה האחרונה נטלת תרופות באופן קבוע או עברת טיפול אחר, או שהמליצו לך ליטול תרופות או לעבור טיפול אחר?',
  'step1.tab3.isAdditionalChronicSmallText': '<span class=\'notation\'>אין צורך לסמן "כן" במידה ומדובר בטיפול הורמונלי, ויטמינים או תוספי מזון, אלרגיה, גלולות למניעת הריון, כולסטרול, תת פעילות בלוטת המגן, קשב וריכוז, לחץ דם ללא שילוב תרופות נוספות, בעיות שינה, צרבות, ערמונית מוגדלת, מיגרנות, טיפול באספירין שלא בעקבות מחלה/ בעיה בלב/ אירוע מוחי או בעיה בקרישה.</span>',
  'step1.tab3.severDisease_title': 'האם אחד מהמובטחים בפוליסה אובחן כחולה או נמצא במצבים הבאים:',
  'step1.tab3.isSeverDisease1': 'מחלות מעיים, אי ספיקת כליות, סכרת תלוית אינסולין,' +
    ' מחלת ריאות תלויית אספקת חמצן, לחץ דם ריאתי, COPD',
  'step1.tab3.isSeverDisease2': 'קרישת יתר, אי ספיקת כבד/ שחמת/ נקרוזיס,' +
		' אנמיה אפלסטית, המופיליה, סיסטיק פיברוזיס- CF',
  'step1.tab3.isSeverDisease3': 'מחלות לב, הפרעות בקוצב לב, אפילפסיה, מושתל איברים, אלצהיימר, טרשת נפוצה- ALS',
  'step1.tab3.isSeverDisease4': 'סרטן, אירוע מוחי, פרקינסון, איידס, נכות פיזית או נפשית מעל 80%',
  'step1.tab3.select_travelers': 'סמן את הנוסעים שעבורם התשובה היא כן:',
  'step1.tab3_extended.title_one_question': 'על מנת לחסוך כסף ולדייק את פרטי הפוליסה, נא לענות על השאלה:',
  'step1.tab3_extended.title_multi_questions': 'על מנת לחסוך כסף ולדייק את פרטי הפוליסה, נא לענות על השאלות:',
  'step1.tab3_extended.isSurgeryMoreThan3Months': 'האם הניתוח / האשפוז כבר בוצע וחלפו מאז למעלה מ 3 חודשים?',
  'step1.tab3.select_traveler': 'רלוונטי לנוסע: ',

  'step1.tab3_extended.isRecommendationForMedicines': 'האם הנך נמצא בטיפול או הומלץ לך על אחד או יותר מהטיפולים הבאים: טיפול בדיאליזה, טיפול בעירוי דם, טיפול במרפאת כאב, טיפול אונקולוגי למעט טיפול משמר, טיפול ביולוגי/נוגד דחייה?',

  'step1.tab3_extended.isAdditionalExistingMedicalCondition': 'האם אובחנת באחד או יותר מהמצבים הרפואיים הבאים: מחלה במערכת העצבים (כדוגמת טרשת נפוצה), קרוהן עם התקפים בשנה האחרונה, מחלת כבד כרונית, מחלה ניוונית כדוגמת ALS, סיסטיק פיברוזיס, COPD, אירוע מוחי בשנה האחרונה, אי ספיקת לב, מחלת כליות כרונית?',

  'step1.tab4.title': 'ניתן להוסיף ולהוריד כיסויים ע"י סימון',
  'step1.tab4.detection': 'איתור וחילוץ- עלות ממוצעת של 0.2 $ ליום',
  'step1.tab4.detection_fulltext': 'הוצאות עבור חיפוש, איתור והצלה של אחד המבוטחים בחו"ל',
  'step1.tab4.detection_bottom1': 'שים לב! בחרת לבטל כיסוי איתור וחילוץ',
  'step1.tab4.liability': 'חבות כלפי צד ג\'- עלות ממוצעת של 0.03 $ ליום',
  'step1.tab4.liability_fulltext': 'הוצאות עבור נזק שגרמת לאדם אחר (צד ג\'), רכוש או גוף',
  'step1.tab4.liability_bottom1': 'שים לב! בחרת לבטל כיסוי חבות כלפי צד ג\'',

  'step1.tab4.suggestion_add_rider_title_luggage': 'מהרו לבטח!',
  'step1.tab4.suggestion_add_rider_title_short_and_cancel': 'טוסו בטוח!',
  'step1.tab4.suggestion_add_rider_title_extremeSport': 'שימו לב!',

  'step1.tab4.suggestion_add_rider_description_luggage': 'מס׳ הנוסעים שדיווחו בקיץ שעבר על איבוד מזוודות זינק ב-30%',
  'step1.tab4.suggestion_add_rider_description_short_and_cancel': 'ביטול טיסה עקב מחלה הוא אחת הסיבות הנפוצות ביותר שבגללן נאלצים לבטל טיסה',
  'step1.tab4.suggestion_add_rider_description_extremeSport': 'בננה בים, טיולי אופניים וג׳יפים ועוד מגוון פעילויות נחשבות לספורט אתגרי',

  'step1.tab4.suggestion_add_rider_subdescription_luggage': 'כדאי לך להוסיף כיסוי לכבודה',
  'step1.tab4.suggestion_add_rider_subdescription_short_and_cancel': 'כדאי לך להוסיף ביטול / קיצור טיסה',
  'step1.tab4.suggestion_add_rider_subdescription_extremeSport': 'אל תאתגרו את עצמכם, תוסיפו את הכיסוי וטוסו בראש שקט',

  'step1.tab4.suggestionAddRiderModal.button1': 'להוספה',
  'step1.tab4.suggestionAddRiderModal.button2': 'לא תודה',

  'step1.tab5.title': 'באפשרותך להוסיף כיסויים אלו לפוליסה ע"י סימון',
  'step1.tab5.charger': 'כבודה (מטען) - עלות ממוצעת של 0.4 $ ליום',
  'step1.tab5.charger_fulltext': 'גניבה או אובדן של הכבודה במשך תקופת הביטוח',
  'step1.tab5.charger_bottom1': ' ',
  'step1.tab5.esports': 'ספורט אתגרי - עלות ממוצעת של 0.7 $ ליום',
  'step1.tab5.esports_fulltext': 'הוצאות שירותים רפואיים בחו"ל שנגרמו כתוצאה מספורט אתגרי. לתשומת לבך, גם טיולי אופניים, שטח ובננה בים נחשבים לספורט אתגרי',
  'step1.tab5.esports_bottom2': 'העלות הממוצעת של כיסוי זה הינה $4 ליום',
  'step1.tab5.esports_bottom1': ' ',
  'step1.tab5.wsports': 'ספורט חורף - עלות ממוצעת של 7.5 $ ליום',
  'step1.tab5.wsports_fulltext': `הוצאות שירותים רפואיים בחו"ל שנגרמו כתוצאה מגלישה והחלקה במסלולים המיועדים לכך
   עבור - מגלש/יים, מזחלות וקרוס קאנטרי`,
  'step1.tab5.wsports_bottom2': 'העלות הממוצעת של כיסוי זה הינה $6 ליום',
  'step1.tab5.wsports_bottom1': ' ',
  'step1.tab5.travelc': 'ביטול נסיעה - עלות ממוצעת של 0.5 $ ליום',
  'step1.tab5.travelc_fulltext': 'הפסד תשלומים כתוצאה מביטול נסיעה בלתי נמנע ע"פ תנאי הפוליסה של חברת הביטוח',
  'step1.tab5.travelc_bottom2': 'העלות הממוצעת של כיסוי זה הינה $1.8 ליום',
  'step1.tab5.travelc_bottom2_ask': ' האם אחד מהמבוטחים או בן משפחה קרוב שהה באשפוז או ביקר במיון בחצי שנה שקדמה לרכישת הפוליסה ו/ או הוגדר מצב סיעודי? ',
  'step1.tab5.travelc_bottom2_notation': 'לתשומת לבך,',
  'step1.tab5.travelc_bottom2_notation2': 'כיסוי להפסד תשלומים בגין קיצור/ביטול נסיעה אינו חל במקרים בהם הנסיעה קוצרה/בוטלה עקב' +
    ' מצב רפואי קיים של המבוטח או של בן משפחה קרוב, במידה והוא ביקר במיון או שהה באשפוז בששת החודשים' +
    ' שקדמו לרכישת הפוליסה ו/או אם הוגדר מצב סיעודי טרם רכישת הכיסוי.',
  'step1.tab5.travelc_bottom1': ' ',
  'step1.tab5.travelsc': 'ביטול או קיצור הנסיעה עקב סיבות רפואיות - עלות ממוצעת של  1 $ ליום',
  'step1.tab5.travelsc_fulltext': 'החזר עבור הוצאות הנסיעה עקב ביטול או קיצור הנסיעה',
  'step1.tab5.travelsc_bottom2': 'העלות הממוצעת של כיסוי זה הינה $1.5 ליום',
  'step1.tab5.travelsc_bottom1': ' ',
  'step1.tab5.pccover': 'מחשב/טאבלט - עלות ממוצעת של 1.25 $ ליום',
  'step1.tab5.pccover_fulltext': 'גניבה של מחשב/טאבלט במשך תקופת הביטוח',
  'step1.tab5.pccover_bottom1': ' ',
  'step1.tab5.pccover_bottom2': 'העלות הממוצעת של כיסוי זה הינה $1 ליום',
  'step1.tab5.pccover_bottom2_manufacturer': 'יצרן',
  'step1.tab5.pccover_bottom2_model': 'דגם',
  'step1.tab5.pccover_bottom2_year': 'שנת ייצור',
  'step1.tab5.pccover_ariaLabel': 'למי שייך המחשב או הטאבלט',
  'step1.tab5.mobilecover': 'טלפון נייד - עלות ממוצעת של 1.25 $ ליום',
  'step1.tab5.mobilecover_fulltext': 'גניבה של טלפון נייד במשך תקופת הביטוח',
  'step1.tab5.mobilecover_bottom1': ' ',
  'step1.tab5.mobilecover_bottom2': 'העלות הממוצעת של כיסוי זה הינה $1.25 ליום',
  'step1.tab5.mobilecover_ariaLabel': 'למי שייך הטלפון הנייד',

  'step1.tab5.cancellationOfSelfParticipationOnRentedCar': 'ביטול השתתפות עצמית לרכב שכור – עלות ממוצעת של 7 $ ליום',
  'step1.tab5.cancellationOfSelfParticipationOnRentedCar_fulltext': 'כיסוי עבור החזר השתתפות עצמית במקרה של ארוע תאונתי לרכב',
  'step1.tab5.cancellationOfSelfParticipationOnRentedCar_explain_what_todo': 'נא לבחור נוסע הצפוי לנהוג ברכב (ניתן לבחור נהג אחד בלבד):',
  'step1.tab5.cancellationOfSelfParticipationOnRentedCar_bottom1': ' ',
  'step1.tab5.cancellationOfSelfParticipationOnRentedCar_bottom2': 'העלות הממוצעת של כיסוי זה הינה $1.25 ליום',
  'step1.tab5.cancellationOfSelfParticipationOnRentedCar_ariaLabel': 'למי שייך הטלפון הנייד',

  'step1.tab5.terms-1': 'אני מאשר/ת את ',
  'step1.tab5.terms-link': 'תנאי השימוש',
  'step1.tab5.terms-2': ' ומאשר/ת קבלת תוכן פרסומי',
  'step1.tab5.terms-ariaLabel': 'אני מאשר מאשרת את-לינק, תנאי השימוש- ומאשר מאשרת קבלת תוכן פרסומי',

  'offers.list.suggestion_title': 'טל, התקבלו 3 הצעות המותאמות לך אישית. הצעות נוספות בדרך..',
  'offers.list.mandatory': 'חובה',
  'offers.list.casco': 'מקיף',
  'offers.list.buy': 'לרכישה',
  'offers.list.buy-selecting': 'תכף ממשיכים',
  'offers.insuredNum': '{num} מבוטחים',
  'offers.duration': 'שהות של {days} ימים',

  'step3.destination': 'יעד: ',
  'step3.insuredNo.': 'מס. מבוטחים:',
  'step3.stayOf': 'שהות של ',
  'step3.stayOf2': ' ימים',
  'step3.mTraveler': 'מבוטח ראשי',
  'step3.mTraveler-f': 'מבוטחת ראשית',
  'step3.traveler': 'מבוטח',
  'step3.traveler-f': 'מבוטחת',
  'step3.firstname_eng': ' שם פרטי (באנגלית)',
  'step3.lastname_eng': ' שם משפחה (באנגלית)',
  'step3.email': ' דואר אלקטרוני',
  'step3.select_city': 'הקלד עיר...',
  'step3.select_street': 'הקלד רחוב...',
  'step3.building_number': 'מס׳ בית',
  'step3.additionalInsuredPhone': 'נייד',
  'step3.additionalInsuredEmail': 'איימיל',
  'step3.savePaymentInfoAgree': 'אני מאשר/ת להעביר את שמי, תעודת הזהות שלי ופרטי ההתקשרות עימי ל-MAX. ידוע לי ואני מאשר/ת שהמידע ייאגר וישמר אצל MAX. השימוש בכרטיס והתשלום באמצעותו כפופים לתנאי הפוליסה.',
  'step3.payWithDigitalCard': '<p class=\'nomargin\'>במקרה של בעיה רפואית בחו”ל, פשוט משלמים עם הכרטיס הדיגיטלי בנייד, שנזמין לך כזה ללא תוספת עלות?</p>' +
    '<p class=\'nomargin\'>*השירות למבוטחים מעל גיל 18 בלבד</p>',
  'step3.advanceToCreditCard': `<p>
  ביטוח נסיעות לחו"ל עם כלל Quick Pay - רוכשים פוליסת נסיעות לחו"ל ומקבלים מקדמה ישירות לכרטיס האשראי, במקרה של הוצאות רפואיות בחו"ל.
  <br>מה צריך לעשות?<br>
  להיכנס לאפליקציית הלחצן של כלל, ללחוץ על "נדרש לי שירות רפואי בחו"ל", להמשיך לקבלת מקדמה והכסף אצלך תוך מספר דקות.
</p>`,
  'cross_sell_banner.title': 'מוזמנים להשוות ולחסוך גם ב Wobi',
  'cross_sell_banner.car_insurance': 'ביטוח רכב',
  'cross_sell_banner.pension': 'סטטוס פנסיוני',
  'cross_sell_banner.apartment_insurance': 'ביטוח דירה',

  'payment.fullname': 'שם מלא של בעל הכרטיס',
  'payment.israeliId': 'ת״ז בעל הכרטיס',
  'payment.cc_number': 'מספר כרטיס אשראי',
  'payment.cc_type': 'סוג כרטיס',
  'payment.cvv': 'ערך אימות כרטיס (CVV)',
  'payment.total_payment': 'סה"כ לתשלום:',
  'payment.total_payment_original': 'מחיר הפוליסה:',
  'payment.currency_rate_1': '*הסכום בשקלים חושב לפי השער היציג',
  'payment.currency_rate_2': 'החיוב בפועל ייעשה לפי השער היציג של בנק ישראל ביום החיוב.',
  'payment.warning': '*אין להזין פרטי כרטיס אשראי מסוג Debit (כרטיס חיוב מידי)',
  'payment.policy_produce_error': 'לקוח יקר, רכישת הפוליסה נכשלה עקב תקלה טכנית, אנא נסה בשנית מאוחר יותר או בחר בחברה אחרת',
  'payment.loading': 'אנחנו מסכמים כרגע את כל המידע ומעבירים לחברת הביטוח.' + '<br>' +
 'בבקשה להמתין במסך זה עד שיוצג בפניך אישור ההזמנה.' + ' <br><br>' +
 '<span style="color:red">' + 'הפעולה עשויה להימשך עד 2 דקות - תודה על הסבלנות' + '</span><br><br>' +
  'שתהיה חופשה מהנה ובטוחה' + '<br>' +
 'תודה שבחרת Wobi',

  'thank_you.tank_you': 'תודה שבחרת לקנות פוליסת ביטוח  עם Wobi סוכנות לביטוח.',
  'thank_you.success': 'הזמנה התקבלה בהצלחה!',
  'thank_you.order_number_text': 'מספר הזמנתך',
  'thank_you.compulsory_message': 'ביטוח נסיעות לחול יכנס לתוקף רק לאחר קבל פוליסה משולמת או העתקה.',
  'thank_you.shipping_message': 'פוליסה מופקרת של חברת הביטוח תשלח בימים הקרובים לביתך.',
  'thank_you.contact_message': 'יש להעביר למוקד בהקדם',
  'thank_you.contact_link': 'אישור היסטוריית תביעות',
  'thank_you.alert_title': 'לתשומת לבך, ',
  'thank_you.alert_message': 'אי מסירת המסמכים שלהלן במועד, עלולה להגדיל את פרמיית ' +
    'הביטוח באופן משמעותי ואף להביא לביטח הביטוח על ידי חברת הביטוח.',
  'thank_you.table_title': 'פרטי הביטוח שרכשת',
  'thank_you.table_label_period': 'תאריכי הנסיעה',
  'thank_you.insurance_period': 'תקופה ביטוח',
  'thank_you.table_value_period': 'מתאריך {start_date} ועד לתאריך {end_date}',
  'thank_you.destination': 'יעד',
  'thank_you.table_label_product_line': 'הביטוח יתבצע',
  'thank_you.table_value_product_line': 'בחברת הכשרה במסלול ״{productline_title}״',
  'thank_you.table_label_vehicle': 'רכב',
  'thank_you.table_value_vehicle': '{licenseNumber} מסוג {manufacturer} {model} משנת {year}',
  'thank_you.table_label_owner': 'בעל הביטוח',
  'thank_you.table_label_price': 'סה"כ לתשלום',
  'thank_you.table_value_price': '₪{price}',
  'thank_you.table_label_cc': 'התשלום יבוצע בכרטיס אשראי שמסתיים בספרות',
  'thank_you.order_confirmation_title': 'אישור הזמנה ועיקרי הביטוח',
  'thank_you.insurance_terms_title': 'תנאי ביטוח מלאים',
  'thank_you.button_download': 'לצפייה בתנאי הביטוח המלאים',
  'thank_you.bottom_notification': 'ביטוח נסיעות לחול יכנס לתוקף רק לאחר קבל פוליסה משולמת או העתקה.',
  'thank_you.bank_of_israel_payment_info': '* הסכום שלפיו תחוייב בפועל יקבע לפי השער היציג של בנק ישראל ביום החיוב',

  'blocked_banner.title': 'בשלב זה לא ניתן להציע כיסוי ביטוחי באמצעות האתר למקרים אלה',
  'blocked_banner.text': 'ניתן לקבל הצעת מחיר מותאמת מנציג על ידי השארת פרטי התקשרות, ונציג יחזור אליך  בהקדם.',

  'unsupported_medical_cases_modal.title': 'הצעה טלפונית',
  'unsupported_medical_cases_modal.first_step.text': 'לאור הפרטים שמסרת נציג ישמח לחזור אליך ' +
  'עם הצעה משתלמת ומותאמת אישית',
  'unsupported_medical_cases_modal.first_step.text_unsupported': 'בשלב זה לא ניתן להציע כיסוי ביטוחי באמצעות האתר למקרים אלה ',
  'unsupported_medical_cases_modal.first_step.button1': 'לחזרה מנציג passportCard',
  'unsupported_medical_cases_modal.first_step.button2': 'לחזרה מנציג שירביט',
  'unsupported_medical_cases_modal.second_step.text': 'פנייתך התקבלה',
  'unsupported_medical_cases_modal.second_step.button': 'אישור',

  'contact_us.terms_conditions_label': 'אני מאשר/ת את תנאי השימוש ומאשר/ת קבלת תוכן פרסומי כאמור',
  'contact_us.terms_conditions_link_text': 'בתנאים המפורטים',

  'pregnancy_and_extreme_sports_esports.title': 'כיסוי “ספורט אתגרי” אינו מכוסה במקרה של הריון',
  'pregnancy_and_extreme_sports_wsports.title': 'כיסוי “ספורט חורף" אינו מכוסה במקרה של הריון',
  'pregnancy_and_extreme_sports_both.title': 'כיסוי “ספורט אתגרי” וכיסוי “ספורט חורף" אינם מכוסים במקרה של הריון',
  'pregnancy_and_extreme_sports_esports_cover.title': '*כיסוי “ספורט אתגרי” אינו בתוקף במקרה של הריון',
  'pregnancy_and_extreme_sports_wsports_cover.title': '*כיסוי “ספורט חורף” אינו בתוקף במקרה של הריון',
  'pregnancy_and_extreme_sports_both_covers.title': '*כיסוי “ספורט אתגרי” וכיסוי “ספורט חורף” אינם בתוקף במקרה של הריון',
  'pregnancy_and_extreme_sports.checkbox': 'אני מאשר/ת את האמור לעיל ומעוניין/ת להתקדם ברכישת הביטוח',
  'pregnancy_and_extreme_sports.button': 'אישור',

};
